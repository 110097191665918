import React from 'react';

const IcoMenNew = (props) => {
    return (
        <svg viewBox="0 0 18 17" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M10 4C10 6.20599 8.20605 8 6 8C3.79395 8 2 6.20599 2 4C2 1.79401 3.79395 0 6 0C8.20605 0 10 1.79401 10 4ZM8.66663 4C8.66663 2.52933 7.4707 1.33334 6 1.33334C4.5293 1.33334 3.33337 2.52933 3.33337 4C3.33337 5.47067 4.5293 6.66666 6 6.66666C7.4707 6.66666 8.66663 5.47067 8.66663 4ZM3.33337 10.6667H6.32422L6.75 9.33334H6.66663H3.33337C1.49536 9.33334 0 10.8287 0 12.6667V16H1.33337V12.6667C1.33337 11.564 2.23071 10.6667 3.33337 10.6667ZM14 12C14.3687 12 14.6666 11.702 14.6666 11.3333C14.6666 10.9647 14.3687 10.6667 14 10.6667H13V9.66666C13 9.298 12.7019 9 12.3333 9C11.9646 9 11.6666 9.298 11.6666 9.66666V10.6667H10.6666C10.298 10.6667 10 10.9647 10 11.3333C10 11.702 10.298 12 10.6666 12H11.6666V13C11.6666 13.3687 11.9646 13.6667 12.3333 13.6667C12.7019 13.6667 13 13.3687 13 13V12H14ZM7.66663 11.3333C7.66663 8.76065 9.76062 6.66666 12.3333 6.66666C14.906 6.66666 17 8.76065 17 11.3333C17 13.906 14.906 16 12.3333 16C9.76062 16 7.66663 13.906 7.66663 11.3333ZM9 11.3333C9 13.1713 10.4952 14.6667 12.3333 14.6667C14.1713 14.6667 15.6666 13.1713 15.6666 11.3333C15.6666 9.49533 14.1713 8 12.3333 8C10.4952 8 9 9.49533 9 11.3333Z"/>
        </svg>
    );
};

export default IcoMenNew;