import React, {useContext, useEffect, useState} from 'react';
import {AuthContext} from "../context/Context";
import Loadit from "../funcs/Load";

const PgLogin = () => {
    // states and vars
    const {Q_, Lg_, Ls_,
        setIsAuth,
        setIsPage,
        setIsPrices,
        setOrders,
        ordersReload, setOrdersReload,

        isHideOldChecked,
        filterCity,
        filterClient,
        filterPartner,
        filterWorker,

        openMessage
    } = useContext(AuthContext)
    const [isLoginError, setIsLoginError] = useState('')
    const [showPswd, setShowPswd] = useState(false)

    // ones for first load
    useEffect(() => {
        // set current menu page
        setIsPage('login')
    }, [])

    // send check for user
    async function checkUser() {
        let userName = Q_('#client-login').value
        let userPassword = Q_('#client-password').value
        const response = await Loadit.getLogin(userName, userPassword)
        Q_('.popup-wait').style.display = 'none';
        if(response.ID){
            Ls_('auth', 'true')
            Ls_('auth-pswd', userPassword)
            Ls_('auth-login', userName)
            Ls_('auth-id', response.ID)
            Ls_('auth-name', response.first_name[0])
            Ls_('auth-surname', response.last_name[0])
            Ls_('auth-role', response.is_role[1])
            Ls_('auth-role-id', response.is_role[0])
            Ls_('auth-role-prices', response.is_role[2])
            Ls_('auth-role-prices-def', response.is_role[5])
            Ls_('auth-role-cities', response.is_role[3])
            Ls_('auth-role-orders-1', response.is_role[4][0])
            Ls_('auth-role-orders-2', response.is_role[4][1])
            Ls_('auth-role-orders-3', response.is_role[4][2])
            Ls_('auth-role-orders-4', response.is_role[4][3])
            Ls_('auth-role-orders-5', response.is_role[4][4])
            Ls_('auth-role-orders-6', response.is_role[4][5])
            Ls_('auth-role-orders-7', response.is_role[4][6])
            Ls_('auth-role-orders-8', response.is_role[4][7])
            Ls_('auth-company', response.company[1])
            Ls_('auth-company-id', response.company[0])
            Ls_('auth-city', response.city[0])
            Ls_('auth-city-id', response.city[1])
            Ls_('auth-signature', response.is_sign)
            Ls_('auth-phone', response.contacts[0])
            Ls_('auth-whatsapp', response.contacts[1])
            Ls_('auth-telegram', response.contacts[2])
            setIsPrices(()=>{
                let tmpPr = 1

                if(Lg_('auth-role-prices') && Lg_('auth-role-prices') !== 'null'){
                    if(Lg_('auth-role-prices') == 0){
                        tmpPr = Lg_('auth-role-prices-def')*1
                    }else if(Lg_('auth-role-prices') == 1){
                        tmpPr = 1
                    }else if(Lg_('auth-role-prices') == 2){
                        tmpPr = 2
                    }else if(Lg_('auth-role-prices') == 3){
                        tmpPr = 3
                    }else if(Lg_('auth-role-prices') == 4){
                        tmpPr = 1
                        if(Lg_('auth-role-prices-def') == 2){
                            tmpPr = 2
                        }
                    }else if(Lg_('auth-role-prices') == 5){
                        tmpPr = 1
                        if(Lg_('auth-role-prices-def') == 3){
                            tmpPr = 3
                        }
                    }else if(Lg_('auth-role-prices') == 6){
                        tmpPr = 2
                        if(Lg_('auth-role-prices-def') == 3){
                            tmpPr = 3
                        }
                    }
                }

                return tmpPr
            })
            setIsAuth(true)

            let responX = await Loadit.getOrders('two', 100, '', filterCity, isHideOldChecked, filterClient, filterPartner, filterWorker)
            setOrders(responX)
            setOrdersReload(!ordersReload)
        }else{
            setIsLoginError(response)
        }
    }
    
    return (
        <div className="page page-login">
            <div className="page-login-form">
                <div className="page-login-logo"> </div>
                <div className="page-login-fields">
                    { isLoginError
                        ? <p className="page-login-form-error" dangerouslySetInnerHTML={{ __html: isLoginError }}></p>
                        : ''
                    }
                    <input id="client-login" className="input" type="text" placeholder="Введите логин или почту"
                           defaultValue={Lg_('auth-login')}
                    />
                    <div className="input-wrp">
                        <input id="client-password" className="input" type={showPswd ? 'text' : 'password'} placeholder="Введите пароль"
                               defaultValue={Lg_('auth-pswd')}
                        />
                        <div className={`input-show ${showPswd ? 'input-show-hide' : ''}`} onClick={() => setShowPswd(!showPswd)}> </div>
                    </div>
                    <div id="get-login" className="input-btn" onClick={() => checkUser()}>Авторизоваться</div>
                </div>
                <div className="page-login-bottom">
                    <span onClick={() => openMessage('Покачто не работает', 'К сожалению данная механика покачто не работает! Пожалуйста свяжитесь с Александром Ш. или с Валерией С. чтобы они восстановили вам пароль.')}>Забыли пароль?</span>
                    <div>
                        <input type="checkbox" autoComplete="off" id="login_rem" name="login_rem"/>
                        <label htmlFor="login_rem">Запомнить меня</label>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PgLogin;