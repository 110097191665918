import React, {useContext, useEffect} from 'react';
import {AuthContext} from "../context/Context";
import OneClient from "../components/OneClient";

const PgClients = () => {
    // states and vars
    const {Q_, Lg_, isSysDat, setIsPage, popClientBlank, setPopClient, setPopClientCur} = useContext(AuthContext)

    // ones for first load
    useEffect(() => {
        // set current menu page
        setIsPage('clients')
    }, [])

    return (
        <div className="page page-clients">
            { isSysDat.st
                ?   <div className="page-clients-wrp">
                        <div className="page-clients-head">
                            <div></div>
                            <div className="create" onClick={()=>{
                                let tmpClientBlank = JSON.parse(JSON.stringify(popClientBlank))
                                tmpClientBlank.osn[0] = true
                                setPopClientCur('client')
                                setPopClient(tmpClientBlank)
                                Q_('.popup-client').style.display = 'grid'
                            }}>
                                Создать клиента
                                <div className="wd">{Lg_('auth-name')} {Lg_('auth-surname')}</div>
                            </div>
                        </div>
                        <div className="page-clients-content">
                            {Object.entries(isSysDat.companies).filter(el => el[1].osn[0] === true).sort(function(a, b){return b[1].id-a[1].id}).map((co)=>{
                                if(co[1].id != 3059){
                                    return(
                                        <OneClient thisClient={co[1]} key={co[1].id} />
                                    )
                                }
                            })}
                        </div>
                    </div>
                :   <h6>Загрузка номенклатур и системных данных, ожидайте...<span>Если очень долго грузится, то нажмите обновить страницу</span></h6>
            }
        </div>
    )
}

export default PgClients