import React from 'react';

const IcoClone = (props) => {
    return (
        <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M13.3869 0.749862C13.942 0.749062 14.4343 0.748352 14.8948 0.926139C15.3553 1.10393 15.7194 1.43529 16.1299 1.80886C16.7229 2.34851 17.3166 2.88739 17.9113 3.42521C18.381 3.85006 18.7981 4.22734 19.0251 4.7398C19.2521 5.25226 19.2512 5.8147 19.2501 6.44808C19.248 7.77624 19.25 9.10442 19.25 10.4326C19.25 11.2083 19.2501 11.8766 19.1781 12.4118C19.101 12.9854 18.9271 13.5352 18.4812 13.9811C18.0352 14.4271 17.4854 14.601 16.9118 14.6781C16.3766 14.7501 15.7083 14.75 14.9326 14.75H11.3174C10.5417 14.75 9.87339 14.7501 9.33819 14.6781C8.76461 14.601 8.2148 14.4271 7.76885 13.9811C7.32289 13.5352 7.14899 12.9854 7.07188 12.4118C6.99992 11.8766 6.99996 11.2082 7 10.4326L7 5.12497C7 4.32474 6.99815 3.63656 7.07188 3.08815C7.14899 2.51458 7.32289 1.96477 7.76885 1.51881C8.2148 1.07286 8.76461 0.898958 9.33819 0.821843C9.87339 0.749887 10.5417 0.749924 11.3174 0.749968C11.3365 0.749969 11.3557 0.74997 11.375 0.74997C12.0456 0.74997 12.7163 0.750828 13.3869 0.749862Z"/>
            <path d="M12.9862 16.625H10.2711C9.53283 16.625 8.81539 16.6251 8.2216 16.5453C7.55157 16.4552 6.78604 16.2357 6.15014 15.5998C5.51424 14.9639 5.29477 14.1984 5.20469 13.5284C5.12485 12.9346 5.12493 12.2172 5.125 11.4789L5.12501 6.02105C5.12498 5.75991 5.12495 5.50139 5.12846 5.25002C4.45685 5.24964 3.78121 5.22825 3.11313 5.31075C2.58748 5.37566 2.07852 5.52127 1.64607 5.89981C1.55862 5.97635 1.47635 6.05862 1.39981 6.14607C1.02127 6.57852 0.875665 7.08748 0.810751 7.61313C0.749935 8.10561 0.749966 8.71622 0.750003 9.42467L0.750002 14.9326C0.749959 15.7083 0.749921 16.3766 0.821877 16.9118C0.898993 17.4854 1.07289 18.0352 1.51885 18.4812C1.9648 18.9271 2.51461 19.101 3.08819 19.1781C3.62339 19.2501 4.29173 19.2501 5.0674 19.25L9.41595 19.25C9.85099 19.25 10.2263 19.25 10.536 19.2261C10.8625 19.2009 11.188 19.1454 11.5042 18.9947C12.0477 18.7357 12.4857 18.2977 12.7447 17.7542C12.9129 17.4012 12.9669 17.0118 12.9862 16.625Z"/>
        </svg>
    );
};

export default IcoClone;