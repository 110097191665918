import React from 'react';

const IcoPlus = (props) => {
    return (
        <svg viewBox="0 0 21 21" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M18 10C18 14.4183 14.4183 18 10 18C5.58172 18 2 14.4183 2 10C2 5.58172 5.58172 2 10 2C14.4183 2 18 5.58172 18 10ZM20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10ZM11 6.25C11 5.69772 10.5523 5.25 10 5.25C9.44772 5.25 9 5.69772 9 6.25V9H6.25C5.69772 9 5.25 9.44772 5.25 10C5.25 10.5523 5.69772 11 6.25 11H9V13.75C9 14.3023 9.44772 14.75 10 14.75C10.5523 14.75 11 14.3023 11 13.75V11H13.75C14.3023 11 14.75 10.5523 14.75 10C14.75 9.44772 14.3023 9 13.75 9H11V6.25Z"/>
        </svg>
    );
};

export default IcoPlus;