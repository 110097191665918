import React, {useContext, useState} from 'react';
import {AuthContext} from "../context/Context";
import IcoTrash from "./IcoTrash";
import IcoGear from "./IcoGear";

const OneNom = (props) => {
    // states and vars
    const {Q_, isSysDat, isLandscape, openMessage, setPopStock} = useContext(AuthContext)
    const [colvo] = useState(() => {
        if(props.thisNom.s){
            return props.thisNom.s.split('_').length
        }else{
            return 0
        }
    })

    return (
        <div className={`one-nom ${'le' + colvo}`}
             onClick={()=>{
                let tmpObj = {
                    id: props.thisNom.id,
                }
                if(props.thisNom.s){
                    props.thisNom.s.split('_').forEach((st)=>{
                        let tmpArr = st.split(':')
                        tmpObj[tmpArr[0]] = tmpArr[1]
                    })
                    setPopStock(tmpObj)
                }else{
                    setPopStock(tmpObj)
                }
                Q_('.popup-instock').style.display = 'grid'
            }}
        >
            <div className="one-nom-id"><p>id: {props.thisNom.id}</p></div>
            <div className="one-nom-name">
                <p>{props.thisNom.name}{!isLandscape ? <i> id:{props.thisNom.id}</i> : ''}</p>
                {props.thisNom.ca ? <span>{isSysDat.cats[props.thisNom.ca]}</span> : ''}
            </div>
            <div></div>
            <div className="one-nom-stock">
                {props.thisNom.s
                ?   props.thisNom.s.split('_').map((st, z)=>{
                        let tmpArr = st.split(':')
                        if(isSysDat.companies[tmpArr[0]]){
                            return(
                                <p className="one-nom-have" key={z}>{isSysDat.companies[tmpArr[0]].nm}: {tmpArr[1]} {props.thisNom.unit}</p>
                            )
                        }
                    })
                :   <p className="one-nom-empty">Нет в наличии на складах</p>
                }
            </div>
            <div className="one-nom-btns" onClick={(e)=>{
                e.preventDefault()
                e.stopPropagation()
                if(!isLandscape){
                    e.target.classList.add('active')
                }
            }}>
                <div className="one-nom-edit" data-tooltip="Редактор номенклатуры"
                     onClick={async (e)=>{
                         e.preventDefault()
                         e.stopPropagation()

                         await props.loadNomData(props.thisNom.id)
                         Q_('.popup-wait').style.display = 'none'
                         Q_('.popup-nomedit').style.display = 'grid'
                     }}
                ><IcoGear/></div>
                <div className="one-nom-delete" data-tooltip="Удалить"
                     onClick={(ev) => {
                        ev.preventDefault()
                        ev.stopPropagation()

                        // message
                        openMessage()
                    }}
                ><IcoTrash/></div>
            </div>
        </div>
    );
};

export default OneNom;