import React from 'react';

const OneHelp = (props) => {
    return (
        <div className="param-help">
            <div className="param-help-ico"
                 onClick={(e)=>{
                     let tmpEl = e.target.closest('.param-help').classList
                     if(tmpEl.contains('active')){
                         tmpEl.remove('active')
                     }else{
                         tmpEl.add('active')
                     }
                 }}
            >i<b>Инструкция к заполнению</b><em>Свернуть инструкцию</em></div>
            <div className="param-help-wrp" dangerouslySetInnerHTML={{ __html: props.inTxt }}
            />
            <div className="param-help-ico ico2"
                 onClick={(e)=>{
                     e.target.closest('.param-help').classList.remove('active')
                 }}
            ><em>Свернуть инструкцию</em></div>
        </div>
    );
};

export default OneHelp;