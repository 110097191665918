import React, {useContext} from 'react';
import {Routes, Route} from "react-router-dom";
import PgOrders from "./pg_Orders";
import PgPaOrders from "./pg_PaOrders";
import PgOrder from "./pg_Order";
import PgLogin from "./pg_Login";
import PgClients from "./pg_Clients";
import PgClient from "./pg_Client";
import PgPartners from "./pg_Partners";
import PgStock from "./pg_Stock";
import {AuthContext} from '../context/Context'

const AppRouter = () => {
    const {isAuth} = useContext(AuthContext)

    return (
        isAuth
            ?
            <Routes>
                <Route
                    path={'/'}
                    element={<PgOrders/>}
                    exact={true}
                />
                <Route
                    path={'/orders'}
                    element={<PgOrders/>}
                    exact={true}
                />
                <Route
                    path={'/pa-orders'}
                    element={<PgPaOrders/>}
                    exact={true}
                />
                <Route
                    path={'/orders/:id'}
                    element={<PgOrder/>}
                    exact={true}
                />
                <Route
                    path={'/clients'}
                    element={<PgClients/>}
                    exact={true}
                />
                <Route
                    path={'/clients/:id'}
                    element={<PgClient/>}
                    exact={true}
                />
                <Route
                    path={'/partners'}
                    element={<PgPartners/>}
                    exact={true}
                />
                <Route
                    path={'/stock'}
                    element={<PgStock/>}
                    exact={true}
                />
            </Routes>
            :
            <Routes>
                <Route
                    path={'/login'}
                    element={<PgLogin/>}
                    exact={true}
                />
                <Route
                    path={'/clients/:id'}
                    element={<PgClient/>}
                    exact={true}
                />
                <Route
                    path={'*'}
                    element={<PgLogin/>}
                    exact={true}
                />
            </Routes>
    )
}

export default AppRouter