import React, {useContext, useEffect} from 'react';
import {AuthContext} from "../context/Context";
import OnePartner from "../components/OnePartner";

const PgPartners = () => {
    // states and vars
    const {Q_, Lg_, isSysDat, setIsPage, popClientBlank, setPopClient, setPopClientCur} = useContext(AuthContext)

    // ones for first load
    useEffect(() => {
        // set current menu page
        setIsPage('partners')
    }, [])

    return (
        <div className="page page-partners">
            { isSysDat.st
                ?   <div className="page-partners-wrp">
                        <div className="page-partners-head">
                            <div></div>
                            <div className="create" onClick={()=>{
                                let tmpClientBlank = JSON.parse(JSON.stringify(popClientBlank))
                                tmpClientBlank.osn[1] = true
                                setPopClientCur('partner')
                                setPopClient(tmpClientBlank)
                                Q_('.popup-client').style.display = 'grid'
                            }}>
                                Создать поставщика
                                <div className="wd">{Lg_('auth-name')} {Lg_('auth-surname')}</div>
                            </div>
                        </div>
                        <div className="page-partners-content">
                            {Object.entries(isSysDat.companies).filter(el => el[1].osn[1] === true).map((co)=>(
                                <OnePartner thisPartner={co[1]} key={co[1].id} />
                            ))}
                        </div>
                    </div>
                :   <h6>Загрузка номенклатур и системных данных, ожидайте...<span>Если очень долго грузится, то нажмите обновить страницу</span></h6>
            }
        </div>
    );
};

export default PgPartners;