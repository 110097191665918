import React from 'react';

const IcoGear = (props) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 14">
            <path d="M13.2,5.6h-0.7c-0.1-0.5-0.4-1-0.6-1.5l0.5-0.5c0.1-0.1,0.2-0.3,0.2-0.5c0-0.2-0.1-0.4-0.2-0.5l-0.9-0.9
                c-0.3-0.3-0.8-0.3-1.1,0L9.9,2.2C9.4,1.9,8.9,1.7,8.4,1.6V0.8C8.4,0.3,8,0,7.6,0H6.4C5.9,0,5.6,0.3,5.6,0.8v0.8
                c-0.5,0.1-1,0.3-1.5,0.6L3.6,1.6c-0.3-0.3-0.8-0.3-1.1,0L1.6,2.5C1.5,2.6,1.4,2.8,1.4,3c0,0.2,0.1,0.4,0.2,0.5l0.5,0.5
                c-0.3,0.5-0.5,1-0.6,1.5H0.8C0.3,5.6,0,6,0,6.4v1.3c0,0.4,0.3,0.8,0.8,0.8h0.7c0.1,0.5,0.4,1,0.6,1.5l-0.5,0.5
                c-0.1,0.1-0.2,0.3-0.2,0.5c0,0.2,0.1,0.4,0.2,0.5l0.9,0.9c0.1,0.1,0.3,0.2,0.5,0.2c0.2,0,0.4-0.1,0.5-0.2l0.6-0.6
                c0.5,0.3,0.9,0.5,1.5,0.6v0.8c0,0.4,0.3,0.8,0.8,0.8h1.3c0.4,0,0.8-0.3,0.8-0.8v-0.8c0.5-0.1,1-0.3,1.5-0.6l0.6,0.6
                c0.1,0.1,0.3,0.2,0.5,0.2c0.2,0,0.4-0.1,0.5-0.2l0.9-0.9c0.1-0.1,0.2-0.3,0.2-0.5c0-0.2-0.1-0.4-0.2-0.5l-0.5-0.5
                c0.3-0.5,0.5-1,0.6-1.5h0.7c0.4,0,0.8-0.3,0.8-0.8V6.4C14,6,13.7,5.6,13.2,5.6z M7,9.4C5.7,9.4,4.6,8.3,4.6,7c0-1.3,1.1-2.4,2.4-2.4
                c1.3,0,2.4,1.1,2.4,2.4C9.4,8.3,8.3,9.4,7,9.4z"
            />
        </svg>
    );
};

export default IcoGear;