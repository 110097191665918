import React from 'react';

const IcoTrash = (props) => {
    return (
        <svg viewBox="0 0 19 19" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M18 4H0V7C1.10457 7 2 7.89543 2 9V13C2 15.8284 2 17.2426 2.87868 18.1213C3.75736 19 5.17157 19 8 19H10C12.8284 19 14.2426 19 15.1213 18.1213C16 17.2426 16 15.8284 16 13V9C16 7.89543 16.8954 7 18 7V4ZM7.5 9C7.5 8.44772 7.05228 8 6.5 8C5.94772 8 5.5 8.44772 5.5 9V14C5.5 14.5523 5.94772 15 6.5 15C7.05228 15 7.5 14.5523 7.5 14V9ZM12.5 9C12.5 8.44772 12.0523 8 11.5 8C10.9477 8 10.5 8.44772 10.5 9V14C10.5 14.5523 10.9477 15 11.5 15C12.0523 15 12.5 14.5523 12.5 14V9Z"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M10.4059 0.12123C9.98198 0.0399005 9.48927 0 9.00003 0C8.51079 5.96046e-08 8.01807 0.0399007 7.59411 0.12123C7.38216 0.161889 7.17466 0.215287 6.98666 0.285625C6.81591 0.349513 6.58302 0.45558 6.38598 0.639419C5.98217 1.01618 5.96024 1.64897 6.33701 2.05278C6.69253 2.43384 7.27603 2.47484 7.67999 2.16168C7.6823 2.16078 7.6848 2.15982 7.68752 2.15881C7.74088 2.13884 7.83356 2.11176 7.9709 2.08542C8.24552 2.03274 8.60873 2 9.00003 2C9.39133 2 9.75454 2.03274 10.0292 2.08542C10.1665 2.11176 10.2592 2.13884 10.3125 2.1588C10.3153 2.15982 10.3178 2.16078 10.3201 2.16168C10.724 2.47484 11.3075 2.43384 11.6631 2.05278C12.0398 1.64897 12.0179 1.01618 11.6141 0.639418C11.417 0.45558 11.1841 0.349512 11.0134 0.285625C10.8254 0.215286 10.6179 0.161888 10.4059 0.12123Z"/>
        </svg>
    );
};

export default IcoTrash;