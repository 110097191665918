import React, {useContext} from 'react';
import {AuthContext} from "../context/Context";

const OneHiddenNom = (props) => {
    // states and vars
    const {Lg_, isSysDat, pushRout} = useContext(AuthContext)

    // return this nomenclature
    function returnThisNom(dataTarget) {
        props.setChanges(false)
        let tmpContent = JSON.parse(JSON.stringify(props.isContent))

        // root is wrp?
        let wrpPos = ''
        if(dataTarget.closest('.element-inside-wrp')){
            wrpPos = dataTarget.closest('.element-inside-wrp').getAttribute('data-pos')
        }

        // create rout of insided noms
        let insRout = []
        insRout.push(props.element[1].slug)
        insRout = pushRout(dataTarget, insRout)

        // pos of nom where we save data
        let papaPos = insRout[insRout.length - 1]

        let contentWay
        if(wrpPos){
            let tmpIndex1 = props.isContent.findIndex(el => el.pos === wrpPos)
            let tmpIndex2 = tmpContent[tmpIndex1].in.findIndex(ell => ell.pos === papaPos.replace('__',''))
            contentWay = tmpContent[tmpIndex1].in[tmpIndex2]
        }else{
            contentWay = tmpContent[props.isContent.findIndex(el => el.pos === papaPos.replace('__',''))]
        }

        if(!contentWay['mod']){
            contentWay['mod'] = {}
        }
        let assignedMod = structuredClone(contentWay['mod'])
        let tmpWay
        insRout.pop()
        insRout.reverse().forEach((el, z) => {
            if(z === 0){
                if(!assignedMod[el]){
                    assignedMod[el] = {}
                }
                tmpWay = assignedMod[el]
            }else{
                if(!tmpWay[el]){
                    tmpWay[el] = {}
                }
                tmpWay = tmpWay[el]
            }

            if(z === insRout.length - 1){
                tmpWay['hidden'] = false
            }
        })
        contentWay['mod'] = assignedMod

        props.setIsContent(tmpContent)
        setTimeout(function () {
            props.setChanges(true)
        },100)
    }

    return (
        <div className={`one-hidden-nom ${Lg_('auth-role-orders-5') > 0 && !props.order.parent_root || Lg_('auth-role-orders-6') > 0 && props.order.parent_root ? 'one-hidden-nom-hide' : ''}`}
             data-deep={props.deep}
        >
            <p>
                <span>{isSysDat.nomenclature[props.element[1].id].name}</span>
                {isSysDat.nomenclature[props.element[1].id].ca ? ' - ' + isSysDat.cats[isSysDat.nomenclature[props.element[1].id].ca] : ''}
            </p>
            <p className="one-hidden-nom-back" onClick={(e) => returnThisNom(e.target)}>
                Восстановить
            </p>
        </div>
    );
};

export default OneHiddenNom;