import React from 'react';

const IcoMenEdit = (props) => {
    return (
        <svg viewBox="0 0 18 17" xmlns="http://www.w3.org/2000/svg">
            <path d="M6 8C8.206 8 10 6.206 10 4C10 1.794 8.206 0 6 0C3.794 0 2 1.794 2 4C2 6.206 3.794 8 6 8ZM6 1.33333C7.47067 1.33333 8.66667 2.52933 8.66667 4C8.66667 5.47067 7.47067 6.66667 6 6.66667C4.52933 6.66667 3.33333 5.47067 3.33333 4C3.33333 2.52933 4.52933 1.33333 6 1.33333ZM16.4147 5.91933C15.6587 5.16333 14.3413 5.16333 13.586 5.91933L6.33333 13.1713V16H9.162L16.414 8.748C16.792 8.37 17 7.868 17 7.33333C17 6.79867 16.792 6.29667 16.4147 5.91933ZM15.4713 7.80467L9.61 13.6667L9.162 13.1713L8.66667 12.7233L14.5287 6.862C14.78 6.61 15.2193 6.61 15.4713 6.862C15.5973 6.98733 15.6667 7.15467 15.6667 7.33333C15.6667 7.512 15.5973 7.67867 15.4713 7.80467ZM6.95267 10.6667H3.33333C2.23067 10.6667 1.33333 11.564 1.33333 12.6667V16H0V12.6667C0 10.8287 1.49533 9.33333 3.33333 9.33333H7.66667C7.85933 9.33333 8.04533 9.35867 8.22933 9.39L6.95267 10.6667Z"/>
        </svg>
    );
};

export default IcoMenEdit;