import React, {useContext, useEffect, useState} from 'react';
import {AuthContext} from "../context/Context";
import {useParams} from "react-router-dom";

const PgClient = () => {
    // states and vars
    const params = useParams()
    const {Q_, Qs_, isSysDat, setIsPage, setPopClientCur, setPopClient} = useContext(AuthContext)
    const [isLoaded, setIsLoaded] = useState(false)

    // ones for first load
    useEffect(() => {
        // set current menu page
        setIsPage('client')
    }, [])

    // when sysDat is loaded
    useEffect(() => {
        if(isLoaded){
            Q_('.popup-client').style.display = 'grid'
            let tmpClient = Object.entries(isSysDat.companies).find(el => el[1].osn[5] === params.id)
            setPopClientCur('client')
            setPopClient(tmpClient[1])
        }else{
            setIsLoaded(true)
        }
    }, [isSysDat])

    return (
        <div className="page page-client">
            {isSysDat.st
                ?   ''
                :   <p className="page-client-loading">Идёт загрузка, ожидайте...</p>
            }
        </div>
    );
};

export default PgClient;